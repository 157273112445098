@keyframes zoomOut {
  from {
    opacity: 0;
    transform: scale(1.4);
  }

  to {
    opacity: 0.3;
    transform: scale(1);
  }
}

@keyframes zoomOutNoOpacity {
  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shining {
  0% {
    background-color: var(--gold);
  }
  25% {
    background-color: var(--white);
  }
  50% {
    background-color: var(--gold);
  }
  75% {
    background-color: var(--white);
  }
  100% {
    background-color: var(--gold);
  }
}

@keyframes shiningLetters {
  0% {
    color: var(--gold);
  }
  25% {
    color: var(--blue);
  }
  50% {
    color: var(--gold);
  }
  75% {
    color: var(--blue);
  }
  100% {
    color: var(--gold);
  }
}

@keyframes shiningLettersV2 {
  0% {
    color: var(--gold);
  }
  25% {
    color: var(--white);
  }
  50% {
    color: var(--gold);
  }
  75% {
    color: var(--white);
  }
  100% {
    color: var(--gold);
  }
}

@keyframes shiningSvg {
  0% {
    fill: var(--gold);
  }
  25% {
    fill: var(--blue);
  }
  50% {
    fill: var(--gold);
  }
  75% {
    fill: var(--blue);
  }
  100% {
    fill: var(--gold);
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(42px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes picImage {
  from {
    opacity: 0;
    transform: scale(1.3) translateY(-32px);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes fillUp {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes floatingBody {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
