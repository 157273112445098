/* Project card */
.project-card {
  color: var(--light-gray);
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 1s;
}

.project-card .card-header,
.project-card .card-footer,
.project-card .visit,
.project-card .view-repository {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.project-card .card-footer {
  margin: auto 0 0 0;
}

.project-card .btn.transparent {
  border: none;
  padding-right: 0;
  padding-left: 0;
}

/* Github and Anchor icons */
.project-card i {
  margin-right: 6px;
}

.project-card .visit svg {
  width: 12px;
  height: 12px;
  fill: var(--blue);
  transition: all 0.3s;
}

/* Visit button */
.project-card .visit {
  justify-content: center;
  text-transform: none;
  padding: 4px;
  border-radius: 4px;
  font-size: var(--fs-ss);
  width: auto;
  margin-left: 18px;
}

.project-card .info {
  display: flex;
  flex-direction: column;
  padding: 22px;
  height: 100%;
}

.project-card .name {
  color: var(--white);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
}

.project-card .tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 18px 0 24px;
}

/* Language tag styles */
.project-card .tags button {
  display: inline-block;
  text-align: center;
  padding: 6px 8px;
  font-family: var(--ff-manrope);
  color: var(--light-gray);
  font-weight: var(--fw-medium);
  font-size: var(--fs-xs);
  letter-spacing: 2px;
  border-radius: 40px;
  opacity: 0.8;
  text-transform: uppercase;
  background-color: var(--blue);
  box-shadow: 0 0 4px var(--light-gray);
  min-width: 66px;
  transition: all 0.3s;
}

.project-card .tags button::before {
  content: "#";
}

.project-card .tags button:hover {
  opacity: 1;
  color: var(--gold);
}

.project-card .tags li {
  margin: 6px 12px 6px 0;
}

/* View repository button */
.project-card .view-repository {
  align-items: center;
  justify-content: flex-start;
  width: auto;
  font-weight: var(--fw-semibold);
  font-size: var(--fs-ss);
  color: var(--light-gray);
  transition: all 0.3s;
}

.project-card .view-repository svg {
  width: 18px;
  fill: var(--light-gray);
  height: 18px;
  transition: all 0.3s;
}

.project-card .view-repository:hover {
  color: var(--gold);
}

.project-card .view-repository:hover svg {
  fill: var(--gold);
}

.project-card .view-repository i {
  margin-right: 8px;
}

.project-card .img-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.project-card .share-on-social .btn {
  min-width: auto;
}

.project-card .share-on-social .btn svg {
  width: 26px;
  fill: var(--white);
  height: 26px;
}

.project-card .share-on-social .btn svg:hover {
  fill: var(--gold);
}

.project-card .share-on-social .links svg {
  width: 20px;
  height: 20px;
}

.project-card .share-on-social .links {
  left: 100%;
}

@media screen and (min-width: 768px) {
  .project-card .name {
    font-size: var(--fs-sl);
  }

  .project-card .view-repository {
    font-size: var(--fs-s);
  }
}
