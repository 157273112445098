.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.wrapper.items-center {
  align-items: center;
}

.strong-gold {
  color: var(--gold);
  font-weight: var(--fw-semibold);
  animation: shiningLettersV2 linear infinite 6s;
}

/* Containers where are Cards wrapper and Show more button */
.grid-container {
  align-items: center;
}

.floating-body {
  animation: floatingBody 12.5s linear infinite;
}

.zoomOut-noOpacity {
  animation: 1.5s ease-out both zoomOutNoOpacity;
}

.section-title {
  color: var(--white);
  font-weight: var(--fw-bold);
  text-transform: capitalize;
  font-size: var(--fs-x);
  margin-bottom: 18px;
}

.card-shadow {
  box-shadow: 0 2px 22px rgba(255, 255, 255, 0.2);
}

.section-message {
  color: var(--light-gray);
  font-size: var(--fs-sl);
  text-align: center;
  margin-bottom: 26px;
  max-width: 1024px;
}

@media screen and (min-width: 1024px) {
  .section-message {
    font-size: var(--fs-sl);
    margin-bottom: 30px;
  }
}
