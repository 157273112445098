.footer {
  padding: 26px;
  font-family: var(--ff-manrope);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
  margin-top: 60px;
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 56px 72px;
  }
}

@media screen and (min-width: 1280px) {
  .footer {
    padding: 76px 112px;
  }
}
