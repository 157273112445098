.personal-info-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.personal-info {
  font-family: var(--ff-manrope);
  font-size: var(--fs-ss);
  color: var(--gold);
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 26px;
  border-radius: 4px;
  width: 100%;
}

.personal-info h4 {
  font-weight: var(--fw-semibold);
  margin-right: 6px;
}

.personal-info span {
  font-weight: var(--fw-medium);
}

.personal-info i {
  border-radius: 12px;
  overflow: hidden;
  margin-left: 6px;
}

.personal-info svg {
  width: 26px;
  height: 26px;
}

.personal-info li span {
  color: var(--white);
}

.personal-info li span.status,
.personal-info li span.from,
.personal-info li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.personal-info li:not(:last-child) {
  margin-bottom: 4px;
}

.personal-info li span.status::before {
  content: "";
  display: inline-block;
  width: 8px;
  margin: 0 4px 0;
  background-color: var(--white);
  height: 8px;
  border-radius: 9101998px;
}

.personal-info li span.status.available::before {
  background-color: var(--available);
}

.personal-info li span.status.busy::before {
  background-color: var(--busy);
}

@media screen and (min-width: 1024px) {
  .personal-info {
    font-size: var(--fs-s);
  }

  .personal-info svg {
    width: 32px;
    height: 32px;
  }

  .personal-info-wrapper {
    flex-direction: row;
    align-items: stretch;
  }

  .personal-info-wrapper .personal-info {
    margin-bottom: 0;
  }

  .personal-info-wrapper .social-networks {
    flex-direction: column;
    justify-content: space-between;
    margin-left: 26px;
  }

  .personal-info-wrapper .social-networks li {
    margin: 0;
  }
}

@media screen and (min-width: 1280px) {
  .personal-info {
    font-size: var(--fs-sl);
  }
}
