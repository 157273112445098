@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.btn {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  font-family: var(--ff-manrope);
  color: var(--blue);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-s);
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  min-width: 78px;
  transition: all 0.2s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
}

.btn i {
  margin-right: 4px;
}

.btn svg {
  width: 22px;
  height: 22px;
}

.btn.white svg {
  fill: var(--blue);
}

.btn.transparent svg {
  fill: var(--gold);
}

.btn:hover {
  transform: scale(1.04);
  -webkit-animation: shiningLetters 5s infinite linear;
          animation: shiningLetters 5s infinite linear;
}

.btn:hover svg {
  -webkit-animation: shiningSvg 6s infinite linear;
          animation: shiningSvg 6s infinite linear;
}

.btn:active {
  transform: scale(0.98);
}

.btn.white {
  background-color: var(--white);
}

.btn.transparent {
  background-color: transparent;
  box-shadow: none;
  border: 2px solid var(--gold);
  color: var(--white);
  -webkit-animation: none;
          animation: none;
}

.btn.transparent svg {
  -webkit-animation: none;
          animation: none;
}

.btn.show-more {
  margin-top: 30px;
}

.btn.small {
  padding: 10px 14px;
  font-size: var(--fs-ss);
  min-width: 78px;
}

.fab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--white);
  border-radius: 9101998px;
  position: fixed;
  z-index: 100;
  transition: all 0.6s;
  opacity: 0.6;
}

.fab-btn.left.bottom {
  left: 26px;
  bottom: 26px;
}

.fab-btn.right.bottom {
  right: 26px;
  bottom: 26px;
}

.fab-btn.hidden {
  bottom: -100% !important;
}

.fab-btn svg {
  width: 30px;
  fill: var(--blue);
  height: 30px;
}

.fab-btn:hover {
  opacity: 1;
}

.fab-btn:active {
  transform: scale(0.9);
}

@media screen and (min-width: 640px) {
  .fab-btn {
    padding: 16px;
  }

  .fab-btn svg {
    width: 38px;
    height: 38px;
  }

  .fab-btn.left.bottom {
    left: 42px;
    bottom: 42px;
  }

  .fab-btn.right.bottom {
    right: 42px;
    bottom: 42px;
  }
}

@media screen and (min-width: 768px) {
  .btn {
    font-size: var(--fs-sl);
  }

  .btn.small {
    padding: 12px 16px;
    font-size: var(--fs-s);
  }

  .fab-btn {
    padding: 14px;
  }

  .fab-btn svg {
    width: 32px;
    height: 32px;
  }

  .fab-btn.left.bottom {
    left: 52px;
    bottom: 32px;
  }

  .fab-btn.right.bottom {
    right: 52px;
    bottom: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .btn {
    font-size: var(--fs-l);
  }

  .btn.small {
    padding: 12px 16px;
    font-size: var(--fs-l);
  }
}

@media screen and (min-width: 1600px) {
  .fab-btn {
    padding: 18px;
  }

  .fab-btn svg {
    width: 42px;
    height: 42px;
  }

  .fab-btn.left.bottom {
    left: calc(50% - 810px);
    bottom: 42px;
  }

  .fab-btn.right.bottom {
    right: calc(50% - 810px);
    bottom: 42px;
  }
}



:root {
  --gold: #ffe479;
  --busy: #feb72b;
  --available: lime;
  --white: #ffffff;
  --blue: #070d59;
  --gray: #777777;
  --light-gray: #eaeaea;
  --light-blue: #070c59af;
  --black: #000;
  --secondary-black: #1e1f20;
  --love-dark: #f82153;

  /* Font family */
  --ff-manrope: "Manrope", "SF Pro Text", -apple-system, BlinkMacSystemFont,
    Roboto, "Segoe IU", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Symbol";

  /* Font weights  */
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;

  /* Font sizes */
  --fs-xs: 10px;
  --fs-vs: 12px;
  --fs-ss: 14px;
  --fs-s: 16px;
  --fs-sl: 18px;
  --fs-l: 20px;
  --fs-m: 24px;
  --fs-x: 32px;
  --fs-xx: 48px;
  --fs-xl: 64px;
}

html {
  background-color: #070d59;
  background-color: var(--blue);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-position: center;
}

html.is-loaded {
  background-image: radial-gradient(
    ellipse at bottom,
    #1e1f20 0%,
    #070d59 100%
  );
  background-image: radial-gradient(
    ellipse at bottom,
    var(--secondary-black) 0%,
    var(--blue) 100%
  );
}

#root {
  display: flex;
  line-height: 1.5;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 360px;
  width: 100%;
  margin: 0 auto;
}

#wallpaper {
  background-position: center;
  background-size: cover;
  opacity: 0;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: -1;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

#wallpaper.is-loaded {
  -webkit-animation-name: zoomOut;
          animation-name: zoomOut;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
ul {
  list-style: none;
}
button,
input,
select,
textarea {
  margin: 0;
}
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
img,
video {
  height: auto;
  max-width: 100%;
}
iframe {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
td:not([align]),
th:not([align]) {
  text-align: left;
}
body,
html {
  width: 100%;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: currentColor;
}

button:focus {
  outline: none;
}

i {
  line-height: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.wrapper.items-center {
  align-items: center;
}

.strong-gold {
  color: var(--gold);
  font-weight: var(--fw-semibold);
  -webkit-animation: shiningLettersV2 linear infinite 6s;
          animation: shiningLettersV2 linear infinite 6s;
}

/* Containers where are Cards wrapper and Show more button */
.grid-container {
  align-items: center;
}

.floating-body {
  -webkit-animation: floatingBody 12.5s linear infinite;
          animation: floatingBody 12.5s linear infinite;
}

.zoomOut-noOpacity {
  -webkit-animation: 1.5s ease-out both zoomOutNoOpacity;
          animation: 1.5s ease-out both zoomOutNoOpacity;
}

.section-title {
  color: var(--white);
  font-weight: var(--fw-bold);
  text-transform: capitalize;
  font-size: var(--fs-x);
  margin-bottom: 18px;
}

.card-shadow {
  box-shadow: 0 2px 22px rgba(255, 255, 255, 0.2);
}

.section-message {
  color: var(--light-gray);
  font-size: var(--fs-sl);
  text-align: center;
  margin-bottom: 26px;
  max-width: 1024px;
}

@media screen and (min-width: 1024px) {
  .section-message {
    font-size: var(--fs-sl);
    margin-bottom: 30px;
  }
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 0;
    transform: scale(1.4);
  }

  to {
    opacity: 0.3;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    opacity: 0;
    transform: scale(1.4);
  }

  to {
    opacity: 0.3;
    transform: scale(1);
  }
}

@-webkit-keyframes zoomOutNoOpacity {
  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOutNoOpacity {
  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes shining {
  0% {
    background-color: var(--gold);
  }
  25% {
    background-color: var(--white);
  }
  50% {
    background-color: var(--gold);
  }
  75% {
    background-color: var(--white);
  }
  100% {
    background-color: var(--gold);
  }
}

@keyframes shining {
  0% {
    background-color: var(--gold);
  }
  25% {
    background-color: var(--white);
  }
  50% {
    background-color: var(--gold);
  }
  75% {
    background-color: var(--white);
  }
  100% {
    background-color: var(--gold);
  }
}

@-webkit-keyframes shiningLetters {
  0% {
    color: var(--gold);
  }
  25% {
    color: var(--blue);
  }
  50% {
    color: var(--gold);
  }
  75% {
    color: var(--blue);
  }
  100% {
    color: var(--gold);
  }
}

@keyframes shiningLetters {
  0% {
    color: var(--gold);
  }
  25% {
    color: var(--blue);
  }
  50% {
    color: var(--gold);
  }
  75% {
    color: var(--blue);
  }
  100% {
    color: var(--gold);
  }
}

@-webkit-keyframes shiningLettersV2 {
  0% {
    color: var(--gold);
  }
  25% {
    color: var(--white);
  }
  50% {
    color: var(--gold);
  }
  75% {
    color: var(--white);
  }
  100% {
    color: var(--gold);
  }
}

@keyframes shiningLettersV2 {
  0% {
    color: var(--gold);
  }
  25% {
    color: var(--white);
  }
  50% {
    color: var(--gold);
  }
  75% {
    color: var(--white);
  }
  100% {
    color: var(--gold);
  }
}

@-webkit-keyframes shiningSvg {
  0% {
    fill: var(--gold);
  }
  25% {
    fill: var(--blue);
  }
  50% {
    fill: var(--gold);
  }
  75% {
    fill: var(--blue);
  }
  100% {
    fill: var(--gold);
  }
}

@keyframes shiningSvg {
  0% {
    fill: var(--gold);
  }
  25% {
    fill: var(--blue);
  }
  50% {
    fill: var(--gold);
  }
  75% {
    fill: var(--blue);
  }
  100% {
    fill: var(--gold);
  }
}

@-webkit-keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(42px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(42px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes picImage {
  from {
    opacity: 0;
    transform: scale(1.3) translateY(-32px);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes picImage {
  from {
    opacity: 0;
    transform: scale(1.3) translateY(-32px);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@-webkit-keyframes fillUp {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes fillUp {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@-webkit-keyframes floatingBody {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes floatingBody {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

#tsparticles {
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 100vw;
  margin: 0 auto;
  height: 100%;
  z-index: -1;
}

/* Spinner in Wrappers loading state */
.double-dot-spin,
.circle-bounce {
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--gold);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

/* Spinner in Images loading state */
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--white);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.circle-spin {
  width: 60px;
  height: 60px;
  position: relative;
}

.circle-spin .circle-spin-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.circle-spin .circle-spin-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: var(--gold);
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.circle-spin .circle-spin2 {
  transform: rotate(30deg);
}
.circle-spin .circle-spin3 {
  transform: rotate(60deg);
}
.circle-spin .circle-spin4 {
  transform: rotate(90deg);
}
.circle-spin .circle-spin5 {
  transform: rotate(120deg);
}
.circle-spin .circle-spin6 {
  transform: rotate(150deg);
}
.circle-spin .circle-spin7 {
  transform: rotate(180deg);
}
.circle-spin .circle-spin8 {
  transform: rotate(210deg);
}
.circle-spin .circle-spin9 {
  transform: rotate(240deg);
}
.circle-spin .circle-spin10 {
  transform: rotate(270deg);
}
.circle-spin .circle-spin11 {
  transform: rotate(300deg);
}
.circle-spin .circle-spin12 {
  transform: rotate(330deg);
}
.circle-spin .circle-spin2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.circle-spin .circle-spin3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.circle-spin .circle-spin4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.circle-spin .circle-spin5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.circle-spin .circle-spin6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.circle-spin .circle-spin7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.circle-spin .circle-spin8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.circle-spin .circle-spin9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.circle-spin .circle-spin10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.circle-spin .circle-spin11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.circle-spin .circle-spin12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@media screen and (min-width: 768px) {
  .double-dot-spin,
  .circle-bounce,
  .circle-spin {
    width: 80px;
    height: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .double-dot-spin,
  .circle-bounce,
  .circle-spin {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 1280px) {
  .double-dot-spin,
  .circle-bounce,
  .circle-spin {
    width: 120px;
    height: 120px;
  }
}

.loading-screen {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.share-on-social {
  width: auto;
  position: relative;
}

.share-on-social,
.share-on-social .links ul,
.share-on-social .links {
  display: flex;
  align-items: center;
}

.share-on-social .links h4 {
  color: var(--blue);
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  margin-right: 16px;
  flex-shrink: 0;
}

.share-on-social .links {
  position: absolute;
  padding: 10px 16px;
  border-radius: 12px 12px 12px 0;
  top: 50%;
  left: 100%;
  transform: translate(-60%, -50%) scale(0);
  border-radius: 8px 8px 8px 0;
  transition: all 0.3s;
  transform-origin: 50%;
}

.share-on-social .links.active {
  transform: translate(0, -50%) scale(1);
}

.share-on-social .links svg {
  width: 26px;
  height: 26px;
  transition: all 0.3s;
}

.share-on-social .links svg {
  fill: var(--gold);
}

.share-on-social .links a:hover svg {
  transform: translateY(-3px);
}

.share-on-social .links.column ul {
  flex-direction: column;
}

.share-on-social .links.column ul li:not(:last-child) {
  margin-bottom: 16px;
}

.share-on-social .links.row ul {
  flex-direction: row;
}

.share-on-social .links.row ul li:not(:last-child) {
  margin-right: 16px;
  margin-bottom: 0;
}

.img-wrapper {
  height: 300px;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 4px;
}

.new-img-loading,
.img-wrapper img {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.img-wrapper img {
  display: inline-block;
  object-fit: cover;
}

.new-img-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-networks,
.social-networks a {
  display: flex;
  align-items: center;
  width: auto;
}

.social-networks li {
  -webkit-animation-name: slideUp;
          animation-name: slideUp;
  -webkit-animation: slideUp 2s cubic-bezier(0, 0.5, 0, 1) both;
          animation: slideUp 2s cubic-bezier(0, 0.5, 0, 1) both;
}

.social-networks li:not(:last-child) {
  margin-right: 36px;
}

.social-networks a {
  justify-content: center;
  opacity: 0.5;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  transition: all 0.3s;
}

.social-networks a:hover {
  opacity: 1;
  transform: scale(1.1);
}

.social-networks a:active {
  opacity: 1;
  transform: scale(0.95);
}

.social-networks svg {
  width: 32px;
  height: 32px;
  fill: var(--gold);
}

@media screen and (min-width: 1024px) {
  .social-networks svg {
    width: 42px;
    height: 42px;
  }
}

.component-switcher {
  align-items: center;
}

.component-switcher .switcher-nav {
  display: flex;
  width: auto;
  justify-content: center;
  margin-bottom: 30px;
}

.component-switcher .switcher-nav li {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.component-switcher .switcher-nav li::before {
  content: "";
  display: inline-block;
  height: 4px;
  background-color: var(--gold);
  border-radius: 40px;
  width: 90%;
  margin-top: 4px;
  transition: all 0.4s;
  transform: scale(0);
  -webkit-animation: shining 6s infinite linear;
          animation: shining 6s infinite linear;
}

.component-switcher .switcher-nav button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
  -webkit-animation: none;
          animation: none;
}

.component-switcher .switcher-nav li:hover button {
  color: var(--gold);
}

.component-switcher .switcher-nav li:not(:last-child) {
  margin-right: 32px;
}

.component-switcher .switcher-nav button {
  font-size: var(--fs-l);
  font-family: var(--ff-manrope);
  font-weight: var(--fw-medium);
  color: var(--white);
  text-transform: capitalize;
  -webkit-animation: none;
          animation: none;
  transition: all 0.3s;
}

.component-switcher li.active button {
  -webkit-animation: shiningLettersV2 5s infinite linear;
          animation: shiningLettersV2 5s infinite linear;
}

.component-switcher li.active::before {
  transform: scale(1);
}

.cards-grid {
  display: grid;
  grid-template-columns: minmax(320px, 420px);
  place-content: center;
  grid-gap: 50px;
  gap: 50px;
  width: 100%;
  max-width: 1400px;
}

@media screen and (min-width: 900px) {
  .cards-grid {
    grid-template-columns: repeat(2, minmax(320px, 480px));
  }
}

@media screen and (min-width: 1280px) {
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.loading-component,
.loading-component-error {
  align-items: center;
}

.loading-component .spinner {
  margin: 100px auto;
}

.loading-component-error .section-message {
  margin-bottom: 0;
  max-width: 640px;
  color: var(--gold);
}

.loading-component-error svg {
  width: 132px;
  height: 132px;
  fill: var(--gold);
}

.loading-component p {
  margin-top: 20px;
}

.header {
  padding: 26px;
  max-width: 1600px;
}

@media screen and (min-width: 768px) {
  .header {
    padding: 36px 72px;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    padding: 56px 112px;
  }
}

.navbar .menu,
.navbar .menu li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navbar .menu li {
  flex-grow: 1;
}

.navbar button {
  color: var(--white);
  font-family: var(--ff-manrope);
  font-size: var(--fs-s);
  text-transform: capitalize;
  font-weight: var(--fw-medium);
  transition: all 0.3s;
}

.navbar button:hover {
  color: var(--gold);
}

@media screen and (min-width: 1024px) {
  .navbar button {
    font-size: var(--fs-sl);
  }
}

@media screen and (min-width: 1280px) {
  .navbar button {
    font-size: var(--fs-m);
  }
}

.about-me {
  align-items: center;
  margin-top: 30px;
  max-width: 1600px;
}

.about-me .buttons {
  display: flex;
  align-items: center;
}

.about-me .buttons .share-on-social {
  margin-left: 26px;
}

.about-me .info {
  font-family: var(--ff-manrope);
  color: var(--white);
}

.about-me aside {
  width: auto;
  margin-bottom: 30px;
  flex-shrink: 0;
}

.about-me figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 320px;
  border-radius: 9101998px;
}

.about-me .profile-picture {
  border-radius: 9101998px;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  -webkit-animation: picImage 1.5s cubic-bezier(0, 0.5, 0.25, 1.25) both;
          animation: picImage 1.5s cubic-bezier(0, 0.5, 0.25, 1.25) both;
  -webkit-animation-delay: 700ms;
          animation-delay: 700ms;
}

.about-me .new-img-loading {
  border-radius: 50%;
}

.about-me h1 {
  font-size: var(--fs-x);
  font-weight: var(--fw-medium);
  margin-bottom: 6px;
  text-align: center;
}

.about-me .job {
  font-size: var(--fs-ss);
  color: var(--gold);
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.about-me hr,
.about-me .description,
.about-me .job,
.about-me .personal-info,
.about-me figure {
  margin-bottom: 26px;
}

.about-me .description {
  font-size: var(--fs-sl);
  text-align: center;
}

.about-me hr {
  -webkit-animation: fillUp both 2s cubic-bezier(0, 0.5, 0, 1);
          animation: fillUp both 2s cubic-bezier(0, 0.5, 0, 1);
  transform-origin: 0%;
  background-color: var(--white);
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
  width: 100%;
}

.about-me .share-on-social .links ul {
  flex-direction: column;
}

.about-me .share-on-social .links {
  top: 100%;
}

.about-me .share-on-social .links ul li:not(:last-child) {
  margin-right: 0;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .about-me figure {
    width: 360px;
    height: 360px;
  }

  .about-me .share-on-social .links ul {
    flex-direction: row;
  }

  .about-me .share-on-social .links {
    top: 50%;
  }

  .about-me .share-on-social .links ul li:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .about-me {
    flex-direction: row-reverse;
    margin-top: 40px;
    align-items: center;
  }

  .about-me .info {
    align-items: flex-start;
  }

  .about-me aside {
    flex-shrink: 0;
    margin-left: 30px;
    margin-bottom: 0;
  }

  .about-me h1 {
    font-size: var(--fs-xx);
  }

  .about-me .job {
    font-size: var(--fs-sl);
  }

  .about-me .description {
    text-align: left;
  }
}

@media screen and (min-width: 1280px) {
  .about-me {
    margin-top: 40px;
  }

  .about-me figure {
    width: 420px;
    height: 420px;
  }

  .about-me aside {
    margin-left: 60px;
  }
}

@media screen and (min-width: 1600px) {
  .about-me figure {
    width: 460px;
    height: 460px;
  }
}

.personal-info-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.personal-info {
  font-family: var(--ff-manrope);
  font-size: var(--fs-ss);
  color: var(--gold);
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 26px;
  border-radius: 4px;
  width: 100%;
}

.personal-info h4 {
  font-weight: var(--fw-semibold);
  margin-right: 6px;
}

.personal-info span {
  font-weight: var(--fw-medium);
}

.personal-info i {
  border-radius: 12px;
  overflow: hidden;
  margin-left: 6px;
}

.personal-info svg {
  width: 26px;
  height: 26px;
}

.personal-info li span {
  color: var(--white);
}

.personal-info li span.status,
.personal-info li span.from,
.personal-info li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.personal-info li:not(:last-child) {
  margin-bottom: 4px;
}

.personal-info li span.status::before {
  content: "";
  display: inline-block;
  width: 8px;
  margin: 0 4px 0;
  background-color: var(--white);
  height: 8px;
  border-radius: 9101998px;
}

.personal-info li span.status.available::before {
  background-color: var(--available);
}

.personal-info li span.status.busy::before {
  background-color: var(--busy);
}

@media screen and (min-width: 1024px) {
  .personal-info {
    font-size: var(--fs-s);
  }

  .personal-info svg {
    width: 32px;
    height: 32px;
  }

  .personal-info-wrapper {
    flex-direction: row;
    align-items: stretch;
  }

  .personal-info-wrapper .personal-info {
    margin-bottom: 0;
  }

  .personal-info-wrapper .social-networks {
    flex-direction: column;
    justify-content: space-between;
    margin-left: 26px;
  }

  .personal-info-wrapper .social-networks li {
    margin: 0;
  }
}

@media screen and (min-width: 1280px) {
  .personal-info {
    font-size: var(--fs-sl);
  }
}

.main {
  padding: 26px;
  font-family: var(--ff-manrope);
  max-width: 1600px;
}

.main > *:not(:last-child) {
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .main {
    padding: 36px 72px;
  }
}

@media screen and (min-width: 1280px) {
  .main {
    padding: 56px 112px;
  }

  .main > *:not(:last-child) {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1600px) {
  .main {
    padding: 0 0 56px;
  }
}

@media screen and (min-width: 640px) {
  .my-skills .brands {
    flex-flow: row wrap;
    justify-content: space-around;
  }
}

.skill-item {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.skill-item .name {
  color: var(--white);
  font-weight: var(--fw-bold);
  font-size: var(--fs-vs);
  text-align: center;
  -webkit-animation: shiningLettersV2 6s infinite linear;
          animation: shiningLettersV2 6s infinite linear;
  max-width: 180px;
}

.skill-item,
.skill-item .circle {
  width: 160px;
  height: 120px;
}

.skill-item .circle {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 9101998px;
  /* background-image: radial-gradient(
    var(--black) 0%,
    rgba(255, 255, 255, 0) 60%
  ); */
  position: absolute;
  bottom: -25%;
  transform: rotateX(65deg);
}

.skill-item .logo {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.skill-item .logo svg {
  width: 42px;
  height: 42px;
}

@media screen and (min-width: 1024px) {
  .skill-item {
    width: 200px;
    height: 200px;
  }

  .skill-item .logo svg {
    width: 62px;
    height: 62px;
  }
}

@media screen and (min-width: 1280px) {
  .skill-item {
    width: 300px;
    height: 200px;
  }

  .skill-item .logo svg {
    width: 82px;
    height: 82px;
  }

  .skill-item .name {
    font-size: var(--fs-ss);
  }
}

.projects-filter-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 18px;
}

/* Language tag styles */
.projects-filter-bar span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  color: var(--light-gray);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-vs);
  font-family: var(--ff-manrope);
  letter-spacing: 2px;
  border-radius: 6px;
  text-transform: uppercase;
  background-color: var(--blue);
  box-shadow: 0 0 4px var(--light-gray);
  position: relative;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s;
}

.projects-filter-bar span:active {
  transform: scale(0.97);
}

.projects-filter-bar button {
  display: inline-block;
  margin-left: 4px;
  font-size: var(--fs-m);
  color: var(--gold);
}

.projects-filter-bar span button::after {
  content: "+";
  display: inline-block;
  transform: rotate(45deg);
  width: auto;
  height: auto;
}

.projects-filter-bar li {
  margin: 6px 12px 6px 0;
}

.projects-filter-bar span:hover {
  opacity: 1;
  transform: translateY(-4px);
}

/* Project card */
.project-card {
  color: var(--light-gray);
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 1s;
}

.project-card .card-header,
.project-card .card-footer,
.project-card .visit,
.project-card .view-repository {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.project-card .card-footer {
  margin: auto 0 0 0;
}

.project-card .btn.transparent {
  border: none;
  padding-right: 0;
  padding-left: 0;
}

/* Github and Anchor icons */
.project-card i {
  margin-right: 6px;
}

.project-card .visit svg {
  width: 12px;
  height: 12px;
  fill: var(--blue);
  transition: all 0.3s;
}

/* Visit button */
.project-card .visit {
  justify-content: center;
  text-transform: none;
  padding: 4px;
  border-radius: 4px;
  font-size: var(--fs-ss);
  width: auto;
  margin-left: 18px;
}

.project-card .info {
  display: flex;
  flex-direction: column;
  padding: 22px;
  height: 100%;
}

.project-card .name {
  color: var(--white);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
}

.project-card .tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 18px 0 24px;
}

/* Language tag styles */
.project-card .tags button {
  display: inline-block;
  text-align: center;
  padding: 6px 8px;
  font-family: var(--ff-manrope);
  color: var(--light-gray);
  font-weight: var(--fw-medium);
  font-size: var(--fs-xs);
  letter-spacing: 2px;
  border-radius: 40px;
  opacity: 0.8;
  text-transform: uppercase;
  background-color: var(--blue);
  box-shadow: 0 0 4px var(--light-gray);
  min-width: 66px;
  transition: all 0.3s;
}

.project-card .tags button::before {
  content: "#";
}

.project-card .tags button:hover {
  opacity: 1;
  color: var(--gold);
}

.project-card .tags li {
  margin: 6px 12px 6px 0;
}

/* View repository button */
.project-card .view-repository {
  align-items: center;
  justify-content: flex-start;
  width: auto;
  font-weight: var(--fw-semibold);
  font-size: var(--fs-ss);
  color: var(--light-gray);
  transition: all 0.3s;
}

.project-card .view-repository svg {
  width: 18px;
  fill: var(--light-gray);
  height: 18px;
  transition: all 0.3s;
}

.project-card .view-repository:hover {
  color: var(--gold);
}

.project-card .view-repository:hover svg {
  fill: var(--gold);
}

.project-card .view-repository i {
  margin-right: 8px;
}

.project-card .img-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.project-card .share-on-social .btn {
  min-width: auto;
}

.project-card .share-on-social .btn svg {
  width: 26px;
  fill: var(--white);
  height: 26px;
}

.project-card .share-on-social .btn svg:hover {
  fill: var(--gold);
}

.project-card .share-on-social .links svg {
  width: 20px;
  height: 20px;
}

.project-card .share-on-social .links {
  left: 100%;
}

@media screen and (min-width: 768px) {
  .project-card .name {
    font-size: var(--fs-sl);
  }

  .project-card .view-repository {
    font-size: var(--fs-s);
  }
}

.certification-card .info {
  padding: 12px 12px 0;
  align-items: center;
}

.certification-card h3,
.certification-card h4 {
  font-weight: var(--fw-bold);
  text-align: center;
}

.certification-card h3 {
  font-size: var(--fs-s);
  color: var(--white);
  -webkit-animation: shiningLettersV2 6s linear infinite;
          animation: shiningLettersV2 6s linear infinite;
}

.certification-card h4 {
  color: var(--light-gray);
  font-size: var(--fs-ss);
}

@media screen and (min-width: 1024px) {
  .certification-card h3 {
    font-size: var(--fs-l);
  }

  .certification-card h4 {
    font-size: var(--fs-sl);
  }
}

@media screen and (min-width: 1280px) {
  .certification-card h3 {
    font-size: var(--fs-m);
  }

  .certification-card h4 {
    font-size: var(--fs-l);
  }
}

.contact {
  align-items: center;
  max-width: 1600px;
}

.contact .loading-component,
.contact .loading-component-error {
  min-height: 590px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  max-width: 480px;
}

@media screen and (min-width: 1024px) {
  .contact .loading-component,
  .contact .loading-component-error {
    min-height: 650px;
  }
}

@media screen and (min-width: 1280px) {
  .contact .loading-component,
  .contact .loading-component-error {
    max-width: 600px;
    min-height: 690px;
  }
}

.contact-info {
  align-items: center;
  max-width: 1024px;
}

.contact-info .contact-info__item,
.contact-info .contact-info__item a {
  font-size: var(--fs-sl);
  font-weight: var(--fw-semibold);
  color: var(--white);
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  transition: all 0.3s;
  cursor: pointer;
}

.contact-info i {
  margin-right: 12px;
}

.contact-info svg {
  width: 24px;
  height: 24px;
  fill: var(--white);
  transition: all 0.3s;
}

.contact-info li:not(:last-child) {
  margin-bottom: 26px;
}

/* Hover styles */
.contact-info .contact-info__item:hover,
.contact-info .contact-info__item a:hover {
  color: var(--gold);
}

.contact-info .contact-info__item:hover svg,
.contact-info .contact-info__item a:hover svg {
  fill: var(--gold);
}
/* Hover styles */

@media screen and (min-width: 1024px) {
  .contact-info {
    flex-flow: row wrap;
    justify-content: center;
  }

  .contact-info .contact-info__item,
  .contact-info .contact-info__item a {
    font-size: var(--fs-l);
  }

  .contact-info li {
    margin: 0 50px 50px 0;
  }

  .contact-info svg {
    width: 32px;
    height: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .contact-info li:not(:last-child) {
    margin-bottom: 36px;
  }
}

.form input,
.form textarea {
  border: 2.5px solid transparent;
  width: 100%;
  color: var(--gold);
  font-family: var(--ff-manrope);
  font-size: var(--fs-s);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  transition: all 0.3s;
}

.form input {
  height: 40px;
  padding: 0 16px 0 12px;
  max-width: 320px;
}

.form .textarea-wrapper {
  position: relative;
  width: 100%;
}

.form textarea {
  resize: none;
  padding: 12px 12px 26px;
  height: 180px;
}

.form .textarea-wrapper .letters-count {
  font-size: var(--fs-ss);
  position: absolute;
  right: 32px;
  bottom: 22px;
  transition: all 0.3s;
}

.form label {
  flex-direction: column-reverse;
}

.form span {
  font-weight: var(--fw-bold);
  font-size: var(--fs-l);
  color: var(--gray);
  text-transform: capitalize;
  margin: 0 0 6px 6px;
  transition: all 0.3s;
}

/* Focus styles */
.form input:focus,
.form textarea:focus {
  outline: none;
  border-color: var(--light-gray);
  box-shadow: 0 2px 12px var(--light-gray);
}

.form input:focus + span {
  color: var(--white);
}

.form textarea:focus + .letters-count {
  color: var(--white);
}

.form input::-webkit-input-placeholder, .form textarea::-webkit-input-placeholder {
  color: var(--gray);
}

.form input:-ms-input-placeholder, .form textarea:-ms-input-placeholder {
  color: var(--gray);
}

.form input::-ms-input-placeholder, .form textarea::-ms-input-placeholder {
  color: var(--gray);
}

.form input::placeholder,
.form textarea::placeholder {
  color: var(--gray);
}

@media screen and (min-width: 1024px) {
  .form span {
    font-size: var(--fs-m);
  }

  .form input {
    height: 45px;
    max-width: 360px;
  }

  .form input,
  .form textarea {
    font-size: var(--fs-sl);
  }

  .form textarea {
    height: 200px;
  }

  .form .textarea-wrapper .letters-count {
    font-size: var(--fs-s);
  }
}

@media screen and (min-width: 1280px) {
  .form input {
    height: 50px;
    max-width: 480px;
  }

  .form textarea {
    height: 220px;
  }
}

.form {
  max-width: 480px;
  font-family: var(--ff-manrope);
  overflow: hidden;
  border-radius: 6px;
  padding: 22px;
}

.form > *:not(:last-child) {
  margin-bottom: 24px;
}

.form.focus {
  box-shadow: 0 2px 22px 2px var(--gold);
}

@media screen and (min-width: 1280px) {
  .form {
    max-width: 600px;
  }
}

.random-quote {
  max-width: 1400px;
  position: relative;
}

.random-quote .quote-body {
  z-index: 20;
  max-width: 640px;
}

.random-quote .quote-body {
  display: flex;
  color: var(--white);
  justify-content: center;
  padding: 22px 0;
  width: 100%;
}

/* Wrapper of .share-twitter & .author */
.random-quote blockquote .wrapper {
  flex-direction: column-reverse;
  align-items: flex-end;
}

.random-quote .share-twitter {
  display: flex;
  align-items: center;
}

/* Twitter icon  */
.random-quote .share-twitter svg {
  width: 26px;
  height: 26px;
  fill: var(--blue);
}

.random-quote .share-twitter i {
  margin-right: 6px;
}

/* Quote left icon */
.random-quote .quote-body i.fa {
  margin-right: 12px;
  margin-top: -8px;
  font-size: var(--fs-l);
}

/* Quote text */
.random-quote blockquote p {
  font-size: var(--fs-s);
  font-weight: var(--fw-medium);
  font-style: italic;
  margin-bottom: 16px;
  line-height: 1.7;
}

.random-quote blockquote .author,
.random-quote .share-twitter {
  font-size: var(--fs-sl);
  font-weight: var(--fw-semibold);
  margin-right: 16px;
}

.random-quote blockquote .author {
  -webkit-animation: shiningLettersV2 6s infinite linear;
          animation: shiningLettersV2 6s infinite linear;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .random-quote .quote-body i.fa {
    font-size: var(--fs-m);
  }

  .random-quote blockquote p {
    font-size: var(--fs-s);
  }

  .random-quote blockquote .author,
  .random-quote .share-twitter {
    font-size: var(--fs-sl);
    margin-bottom: 0;
  }

  .random-quote .share-twitter {
    margin-right: 0;
  }

  .random-quote blockquote .wrapper {
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .random-quote .quote-body i.fa {
    font-size: var(--fs-x);
  }

  .random-quote blockquote p {
    font-size: var(--fs-sl);
  }

  .random-quote blockquote .author {
    font-size: var(--fs-l);
  }
}

.footer {
  padding: 26px;
  font-family: var(--ff-manrope);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
  margin-top: 60px;
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 56px 72px;
  }
}

@media screen and (min-width: 1280px) {
  .footer {
    padding: 76px 112px;
  }
}

.footer-message {
  max-width: 1440px;
}

.footer-message p {
  display: flex;
  align-items: center;
  font-size: var(--fs-sl);
  color: var(--white);
}

.footer-message p svg {
  width: 32px;
  height: 32px;
  fill: var(--gold);
}

.footer-message .heart svg {
  fill: var(--love-dark);
}

.footer-message > *:not(:last-child) {
  margin-bottom: 30px;
}

.footer-message p i {
  margin: 0 6px;
}

@media screen and (min-width: 1070px) {
  .footer-message {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-message > *:not(:last-child) {
    margin-bottom: 0;
  }

  .footer-message p {
    font-size: var(--fs-l);
  }
}

@media screen and (min-width: 1024px) {
  .footer-message .section-message {
    font-size: var(--fs-m);
  }
}

/* --- Fonts --- */

/* --- Base styles and variables --- */

/* --- Utils and shared styles --- */

/* --- Animations --- */

/* Animated Spinners */

/* --- Components ---  */

/* Loading Screen */

/* Share on Social */

/* Card Image */

/* Buttons */

/* Social Networks */

/* Component Switcher */

/* Cards Grid Projects and Certifications */

/* Loading Component*/

/*  <Header>  */

/*  </Header>  */

/* <Main> */
/* Skills */

/* Portfolio */

/* Contact */

/* RandomQuote */

/* </Main> */

/* <Footer> */

/* </Footer> */

