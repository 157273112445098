.contact-info {
  align-items: center;
  max-width: 1024px;
}

.contact-info .contact-info__item,
.contact-info .contact-info__item a {
  font-size: var(--fs-sl);
  font-weight: var(--fw-semibold);
  color: var(--white);
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  transition: all 0.3s;
  cursor: pointer;
}

.contact-info i {
  margin-right: 12px;
}

.contact-info svg {
  width: 24px;
  height: 24px;
  fill: var(--white);
  transition: all 0.3s;
}

.contact-info li:not(:last-child) {
  margin-bottom: 26px;
}

/* Hover styles */
.contact-info .contact-info__item:hover,
.contact-info .contact-info__item a:hover {
  color: var(--gold);
}

.contact-info .contact-info__item:hover svg,
.contact-info .contact-info__item a:hover svg {
  fill: var(--gold);
}
/* Hover styles */

@media screen and (min-width: 1024px) {
  .contact-info {
    flex-flow: row wrap;
    justify-content: center;
  }

  .contact-info .contact-info__item,
  .contact-info .contact-info__item a {
    font-size: var(--fs-l);
  }

  .contact-info li {
    margin: 0 50px 50px 0;
  }

  .contact-info svg {
    width: 32px;
    height: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .contact-info li:not(:last-child) {
    margin-bottom: 36px;
  }
}
