.about-me {
  align-items: center;
  margin-top: 30px;
  max-width: 1600px;
}

.about-me .buttons {
  display: flex;
  align-items: center;
}

.about-me .buttons .share-on-social {
  margin-left: 26px;
}

.about-me .info {
  font-family: var(--ff-manrope);
  color: var(--white);
}

.about-me aside {
  width: auto;
  margin-bottom: 30px;
  flex-shrink: 0;
}

.about-me figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 320px;
  border-radius: 9101998px;
}

.about-me .profile-picture {
  border-radius: 9101998px;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  animation: picImage 1.5s cubic-bezier(0, 0.5, 0.25, 1.25) both;
  animation-delay: 700ms;
}

.about-me .new-img-loading {
  border-radius: 50%;
}

.about-me h1 {
  font-size: var(--fs-x);
  font-weight: var(--fw-medium);
  margin-bottom: 6px;
  text-align: center;
}

.about-me .job {
  font-size: var(--fs-ss);
  color: var(--gold);
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.about-me hr,
.about-me .description,
.about-me .job,
.about-me .personal-info,
.about-me figure {
  margin-bottom: 26px;
}

.about-me .description {
  font-size: var(--fs-sl);
  text-align: center;
}

.about-me hr {
  animation: fillUp both 2s cubic-bezier(0, 0.5, 0, 1);
  transform-origin: 0%;
  background-color: var(--white);
  animation-delay: 500ms;
  width: 100%;
}

.about-me .share-on-social .links ul {
  flex-direction: column;
}

.about-me .share-on-social .links {
  top: 100%;
}

.about-me .share-on-social .links ul li:not(:last-child) {
  margin-right: 0;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .about-me figure {
    width: 360px;
    height: 360px;
  }

  .about-me .share-on-social .links ul {
    flex-direction: row;
  }

  .about-me .share-on-social .links {
    top: 50%;
  }

  .about-me .share-on-social .links ul li:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .about-me {
    flex-direction: row-reverse;
    margin-top: 40px;
    align-items: center;
  }

  .about-me .info {
    align-items: flex-start;
  }

  .about-me aside {
    flex-shrink: 0;
    margin-left: 30px;
    margin-bottom: 0;
  }

  .about-me h1 {
    font-size: var(--fs-xx);
  }

  .about-me .job {
    font-size: var(--fs-sl);
  }

  .about-me .description {
    text-align: left;
  }
}

@media screen and (min-width: 1280px) {
  .about-me {
    margin-top: 40px;
  }

  .about-me figure {
    width: 420px;
    height: 420px;
  }

  .about-me aside {
    margin-left: 60px;
  }
}

@media screen and (min-width: 1600px) {
  .about-me figure {
    width: 460px;
    height: 460px;
  }
}
