.contact {
  align-items: center;
  max-width: 1600px;
}

.contact .loading-component,
.contact .loading-component-error {
  min-height: 590px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  max-width: 480px;
}

@media screen and (min-width: 1024px) {
  .contact .loading-component,
  .contact .loading-component-error {
    min-height: 650px;
  }
}

@media screen and (min-width: 1280px) {
  .contact .loading-component,
  .contact .loading-component-error {
    max-width: 600px;
    min-height: 690px;
  }
}
