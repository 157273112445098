.social-networks,
.social-networks a {
  display: flex;
  align-items: center;
  width: auto;
}

.social-networks li {
  animation-name: slideUp;
  animation: slideUp 2s cubic-bezier(0, 0.5, 0, 1) both;
}

.social-networks li:not(:last-child) {
  margin-right: 36px;
}

.social-networks a {
  justify-content: center;
  opacity: 0.5;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  transition: all 0.3s;
}

.social-networks a:hover {
  opacity: 1;
  transform: scale(1.1);
}

.social-networks a:active {
  opacity: 1;
  transform: scale(0.95);
}

.social-networks svg {
  width: 32px;
  height: 32px;
  fill: var(--gold);
}

@media screen and (min-width: 1024px) {
  .social-networks svg {
    width: 42px;
    height: 42px;
  }
}
