.random-quote {
  max-width: 1400px;
  position: relative;
}

.random-quote .quote-body {
  z-index: 20;
  max-width: 640px;
}

.random-quote .quote-body {
  display: flex;
  color: var(--white);
  justify-content: center;
  padding: 22px 0;
  width: 100%;
}

/* Wrapper of .share-twitter & .author */
.random-quote blockquote .wrapper {
  flex-direction: column-reverse;
  align-items: flex-end;
}

.random-quote .share-twitter {
  display: flex;
  align-items: center;
}

/* Twitter icon  */
.random-quote .share-twitter svg {
  width: 26px;
  height: 26px;
  fill: var(--blue);
}

.random-quote .share-twitter i {
  margin-right: 6px;
}

/* Quote left icon */
.random-quote .quote-body i.fa {
  margin-right: 12px;
  margin-top: -8px;
  font-size: var(--fs-l);
}

/* Quote text */
.random-quote blockquote p {
  font-size: var(--fs-s);
  font-weight: var(--fw-medium);
  font-style: italic;
  margin-bottom: 16px;
  line-height: 1.7;
}

.random-quote blockquote .author,
.random-quote .share-twitter {
  font-size: var(--fs-sl);
  font-weight: var(--fw-semibold);
  margin-right: 16px;
}

.random-quote blockquote .author {
  animation: shiningLettersV2 6s infinite linear;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .random-quote .quote-body i.fa {
    font-size: var(--fs-m);
  }

  .random-quote blockquote p {
    font-size: var(--fs-s);
  }

  .random-quote blockquote .author,
  .random-quote .share-twitter {
    font-size: var(--fs-sl);
    margin-bottom: 0;
  }

  .random-quote .share-twitter {
    margin-right: 0;
  }

  .random-quote blockquote .wrapper {
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .random-quote .quote-body i.fa {
    font-size: var(--fs-x);
  }

  .random-quote blockquote p {
    font-size: var(--fs-sl);
  }

  .random-quote blockquote .author {
    font-size: var(--fs-l);
  }
}
