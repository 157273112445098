/* Spinner in Wrappers loading state */
.double-dot-spin,
.circle-bounce {
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--gold);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

/* Spinner in Images loading state */
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--white);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.circle-spin {
  width: 60px;
  height: 60px;
  position: relative;
}

.circle-spin .circle-spin-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.circle-spin .circle-spin-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: var(--gold);
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.circle-spin .circle-spin2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.circle-spin .circle-spin3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.circle-spin .circle-spin4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.circle-spin .circle-spin5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.circle-spin .circle-spin6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.circle-spin .circle-spin7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.circle-spin .circle-spin8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.circle-spin .circle-spin9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.circle-spin .circle-spin10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.circle-spin .circle-spin11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.circle-spin .circle-spin12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.circle-spin .circle-spin2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.circle-spin .circle-spin3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.circle-spin .circle-spin4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.circle-spin .circle-spin5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.circle-spin .circle-spin6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.circle-spin .circle-spin7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.circle-spin .circle-spin8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.circle-spin .circle-spin9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.circle-spin .circle-spin10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.circle-spin .circle-spin11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.circle-spin .circle-spin12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@media screen and (min-width: 768px) {
  .double-dot-spin,
  .circle-bounce,
  .circle-spin {
    width: 80px;
    height: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .double-dot-spin,
  .circle-bounce,
  .circle-spin {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 1280px) {
  .double-dot-spin,
  .circle-bounce,
  .circle-spin {
    width: 120px;
    height: 120px;
  }
}
