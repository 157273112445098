.main {
  padding: 26px;
  font-family: var(--ff-manrope);
  max-width: 1600px;
}

.main > *:not(:last-child) {
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .main {
    padding: 36px 72px;
  }
}

@media screen and (min-width: 1280px) {
  .main {
    padding: 56px 112px;
  }

  .main > *:not(:last-child) {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1600px) {
  .main {
    padding: 0 0 56px;
  }
}
