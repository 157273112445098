.projects-filter-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 18px;
}

/* Language tag styles */
.projects-filter-bar span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  color: var(--light-gray);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-vs);
  font-family: var(--ff-manrope);
  letter-spacing: 2px;
  border-radius: 6px;
  text-transform: uppercase;
  background-color: var(--blue);
  box-shadow: 0 0 4px var(--light-gray);
  position: relative;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s;
}

.projects-filter-bar span:active {
  transform: scale(0.97);
}

.projects-filter-bar button {
  display: inline-block;
  margin-left: 4px;
  font-size: var(--fs-m);
  color: var(--gold);
}

.projects-filter-bar span button::after {
  content: "+";
  display: inline-block;
  transform: rotate(45deg);
  width: auto;
  height: auto;
}

.projects-filter-bar li {
  margin: 6px 12px 6px 0;
}

.projects-filter-bar span:hover {
  opacity: 1;
  transform: translateY(-4px);
}
