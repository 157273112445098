.loading-component,
.loading-component-error {
  align-items: center;
}

.loading-component .spinner {
  margin: 100px auto;
}

.loading-component-error .section-message {
  margin-bottom: 0;
  max-width: 640px;
  color: var(--gold);
}

.loading-component-error svg {
  width: 132px;
  height: 132px;
  fill: var(--gold);
}

.loading-component p {
  margin-top: 20px;
}
