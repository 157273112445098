.navbar .menu,
.navbar .menu li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navbar .menu li {
  flex-grow: 1;
}

.navbar button {
  color: var(--white);
  font-family: var(--ff-manrope);
  font-size: var(--fs-s);
  text-transform: capitalize;
  font-weight: var(--fw-medium);
  transition: all 0.3s;
}

.navbar button:hover {
  color: var(--gold);
}

@media screen and (min-width: 1024px) {
  .navbar button {
    font-size: var(--fs-sl);
  }
}

@media screen and (min-width: 1280px) {
  .navbar button {
    font-size: var(--fs-m);
  }
}
