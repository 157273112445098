.certification-card .info {
  padding: 12px 12px 0;
  align-items: center;
}

.certification-card h3,
.certification-card h4 {
  font-weight: var(--fw-bold);
  text-align: center;
}

.certification-card h3 {
  font-size: var(--fs-s);
  color: var(--white);
  animation: shiningLettersV2 6s linear infinite;
}

.certification-card h4 {
  color: var(--light-gray);
  font-size: var(--fs-ss);
}

@media screen and (min-width: 1024px) {
  .certification-card h3 {
    font-size: var(--fs-l);
  }

  .certification-card h4 {
    font-size: var(--fs-sl);
  }
}

@media screen and (min-width: 1280px) {
  .certification-card h3 {
    font-size: var(--fs-m);
  }

  .certification-card h4 {
    font-size: var(--fs-l);
  }
}
