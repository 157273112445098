:root {
  --gold: #ffe479;
  --busy: #feb72b;
  --available: lime;
  --white: #ffffff;
  --blue: #070d59;
  --gray: #777777;
  --light-gray: #eaeaea;
  --light-blue: #070c59af;
  --black: #000;
  --secondary-black: #1e1f20;
  --love-dark: #f82153;

  /* Font family */
  --ff-manrope: "Manrope", "SF Pro Text", -apple-system, BlinkMacSystemFont,
    Roboto, "Segoe IU", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Symbol";

  /* Font weights  */
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;

  /* Font sizes */
  --fs-xs: 10px;
  --fs-vs: 12px;
  --fs-ss: 14px;
  --fs-s: 16px;
  --fs-sl: 18px;
  --fs-l: 20px;
  --fs-m: 24px;
  --fs-x: 32px;
  --fs-xx: 48px;
  --fs-xl: 64px;
}

html {
  background-color: var(--blue);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-position: center;
}

html.is-loaded {
  background-image: radial-gradient(
    ellipse at bottom,
    var(--secondary-black) 0%,
    var(--blue) 100%
  );
}

#root {
  display: flex;
  line-height: 1.5;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 360px;
  width: 100%;
  margin: 0 auto;
}

#wallpaper {
  background-position: center;
  background-size: cover;
  opacity: 0;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: -1;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

#wallpaper.is-loaded {
  animation-name: zoomOut;
}
