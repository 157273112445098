.component-switcher {
  align-items: center;
}

.component-switcher .switcher-nav {
  display: flex;
  width: auto;
  justify-content: center;
  margin-bottom: 30px;
}

.component-switcher .switcher-nav li {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.component-switcher .switcher-nav li::before {
  content: "";
  display: inline-block;
  height: 4px;
  background-color: var(--gold);
  border-radius: 40px;
  width: 90%;
  margin-top: 4px;
  transition: all 0.4s;
  transform: scale(0);
  animation: shining 6s infinite linear;
}

.component-switcher .switcher-nav button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
  animation: none;
}

.component-switcher .switcher-nav li:hover button {
  color: var(--gold);
}

.component-switcher .switcher-nav li:not(:last-child) {
  margin-right: 32px;
}

.component-switcher .switcher-nav button {
  font-size: var(--fs-l);
  font-family: var(--ff-manrope);
  font-weight: var(--fw-medium);
  color: var(--white);
  text-transform: capitalize;
  animation: none;
  transition: all 0.3s;
}

.component-switcher li.active button {
  animation: shiningLettersV2 5s infinite linear;
}

.component-switcher li.active::before {
  transform: scale(1);
}
