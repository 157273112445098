.skill-item {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.skill-item .name {
  color: var(--white);
  font-weight: var(--fw-bold);
  font-size: var(--fs-vs);
  text-align: center;
  animation: shiningLettersV2 6s infinite linear;
  max-width: 180px;
}

.skill-item,
.skill-item .circle {
  width: 160px;
  height: 120px;
}

.skill-item .circle {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 9101998px;
  /* background-image: radial-gradient(
    var(--black) 0%,
    rgba(255, 255, 255, 0) 60%
  ); */
  position: absolute;
  bottom: -25%;
  transform: rotateX(65deg);
}

.skill-item .logo {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.skill-item .logo svg {
  width: 42px;
  height: 42px;
}

@media screen and (min-width: 1024px) {
  .skill-item {
    width: 200px;
    height: 200px;
  }

  .skill-item .logo svg {
    width: 62px;
    height: 62px;
  }
}

@media screen and (min-width: 1280px) {
  .skill-item {
    width: 300px;
    height: 200px;
  }

  .skill-item .logo svg {
    width: 82px;
    height: 82px;
  }

  .skill-item .name {
    font-size: var(--fs-ss);
  }
}
