.form {
  max-width: 480px;
  font-family: var(--ff-manrope);
  overflow: hidden;
  border-radius: 6px;
  padding: 22px;
}

.form > *:not(:last-child) {
  margin-bottom: 24px;
}

.form.focus {
  box-shadow: 0 2px 22px 2px var(--gold);
}

@media screen and (min-width: 1280px) {
  .form {
    max-width: 600px;
  }
}
