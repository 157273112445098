.img-wrapper {
  height: 300px;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 4px;
}

.new-img-loading,
.img-wrapper img {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.img-wrapper img {
  display: inline-block;
  object-fit: cover;
}

.new-img-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
