.header {
  padding: 26px;
  max-width: 1600px;
}

@media screen and (min-width: 768px) {
  .header {
    padding: 36px 72px;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    padding: 56px 112px;
  }
}
