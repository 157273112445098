.footer-message {
  max-width: 1440px;
}

.footer-message p {
  display: flex;
  align-items: center;
  font-size: var(--fs-sl);
  color: var(--white);
}

.footer-message p svg {
  width: 32px;
  height: 32px;
  fill: var(--gold);
}

.footer-message .heart svg {
  fill: var(--love-dark);
}

.footer-message > *:not(:last-child) {
  margin-bottom: 30px;
}

.footer-message p i {
  margin: 0 6px;
}

@media screen and (min-width: 1070px) {
  .footer-message {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-message > *:not(:last-child) {
    margin-bottom: 0;
  }

  .footer-message p {
    font-size: var(--fs-l);
  }
}

@media screen and (min-width: 1024px) {
  .footer-message .section-message {
    font-size: var(--fs-m);
  }
}
