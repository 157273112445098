.form input,
.form textarea {
  border: 2.5px solid transparent;
  width: 100%;
  color: var(--gold);
  font-family: var(--ff-manrope);
  font-size: var(--fs-s);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  transition: all 0.3s;
}

.form input {
  height: 40px;
  padding: 0 16px 0 12px;
  max-width: 320px;
}

.form .textarea-wrapper {
  position: relative;
  width: 100%;
}

.form textarea {
  resize: none;
  padding: 12px 12px 26px;
  height: 180px;
}

.form .textarea-wrapper .letters-count {
  font-size: var(--fs-ss);
  position: absolute;
  right: 32px;
  bottom: 22px;
  transition: all 0.3s;
}

.form label {
  flex-direction: column-reverse;
}

.form span {
  font-weight: var(--fw-bold);
  font-size: var(--fs-l);
  color: var(--gray);
  text-transform: capitalize;
  margin: 0 0 6px 6px;
  transition: all 0.3s;
}

/* Focus styles */
.form input:focus,
.form textarea:focus {
  outline: none;
  border-color: var(--light-gray);
  box-shadow: 0 2px 12px var(--light-gray);
}

.form input:focus + span {
  color: var(--white);
}

.form textarea:focus + .letters-count {
  color: var(--white);
}

.form input::placeholder,
.form textarea::placeholder {
  color: var(--gray);
}

@media screen and (min-width: 1024px) {
  .form span {
    font-size: var(--fs-m);
  }

  .form input {
    height: 45px;
    max-width: 360px;
  }

  .form input,
  .form textarea {
    font-size: var(--fs-sl);
  }

  .form textarea {
    height: 200px;
  }

  .form .textarea-wrapper .letters-count {
    font-size: var(--fs-s);
  }
}

@media screen and (min-width: 1280px) {
  .form input {
    height: 50px;
    max-width: 480px;
  }

  .form textarea {
    height: 220px;
  }
}
