.cards-grid {
  display: grid;
  grid-template-columns: minmax(320px, 420px);
  place-content: center;
  gap: 50px;
  width: 100%;
  max-width: 1400px;
}

@media screen and (min-width: 900px) {
  .cards-grid {
    grid-template-columns: repeat(2, minmax(320px, 480px));
  }
}

@media screen and (min-width: 1280px) {
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
