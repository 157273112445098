#tsparticles {
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 100vw;
  margin: 0 auto;
  height: 100%;
  z-index: -1;
}
