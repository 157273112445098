.share-on-social {
  width: auto;
  position: relative;
}

.share-on-social,
.share-on-social .links ul,
.share-on-social .links {
  display: flex;
  align-items: center;
}

.share-on-social .links h4 {
  color: var(--blue);
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  margin-right: 16px;
  flex-shrink: 0;
}

.share-on-social .links {
  position: absolute;
  padding: 10px 16px;
  border-radius: 12px 12px 12px 0;
  top: 50%;
  left: 100%;
  transform: translate(-60%, -50%) scale(0);
  border-radius: 8px 8px 8px 0;
  transition: all 0.3s;
  transform-origin: 50%;
}

.share-on-social .links.active {
  transform: translate(0, -50%) scale(1);
}

.share-on-social .links svg {
  width: 26px;
  height: 26px;
  transition: all 0.3s;
}

.share-on-social .links svg {
  fill: var(--gold);
}

.share-on-social .links a:hover svg {
  transform: translateY(-3px);
}

.share-on-social .links.column ul {
  flex-direction: column;
}

.share-on-social .links.column ul li:not(:last-child) {
  margin-bottom: 16px;
}

.share-on-social .links.row ul {
  flex-direction: row;
}

.share-on-social .links.row ul li:not(:last-child) {
  margin-right: 16px;
  margin-bottom: 0;
}
