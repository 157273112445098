.btn {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  font-family: var(--ff-manrope);
  color: var(--blue);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-s);
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  min-width: 78px;
  transition: all 0.2s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
}

.btn i {
  margin-right: 4px;
}

.btn svg {
  width: 22px;
  height: 22px;
}

.btn.white svg {
  fill: var(--blue);
}

.btn.transparent svg {
  fill: var(--gold);
}

.btn:hover {
  transform: scale(1.04);
  animation: shiningLetters 5s infinite linear;
}

.btn:hover svg {
  animation: shiningSvg 6s infinite linear;
}

.btn:active {
  transform: scale(0.98);
}

.btn.white {
  background-color: var(--white);
}

.btn.transparent {
  background-color: transparent;
  box-shadow: none;
  border: 2px solid var(--gold);
  color: var(--white);
  animation: none;
}

.btn.transparent svg {
  animation: none;
}

.btn.show-more {
  margin-top: 30px;
}

.btn.small {
  padding: 10px 14px;
  font-size: var(--fs-ss);
  min-width: 78px;
}

.fab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--white);
  border-radius: 9101998px;
  position: fixed;
  z-index: 100;
  transition: all 0.6s;
  opacity: 0.6;
}

.fab-btn.left.bottom {
  left: 26px;
  bottom: 26px;
}

.fab-btn.right.bottom {
  right: 26px;
  bottom: 26px;
}

.fab-btn.hidden {
  bottom: -100% !important;
}

.fab-btn svg {
  width: 30px;
  fill: var(--blue);
  height: 30px;
}

.fab-btn:hover {
  opacity: 1;
}

.fab-btn:active {
  transform: scale(0.9);
}

@media screen and (min-width: 640px) {
  .fab-btn {
    padding: 16px;
  }

  .fab-btn svg {
    width: 38px;
    height: 38px;
  }

  .fab-btn.left.bottom {
    left: 42px;
    bottom: 42px;
  }

  .fab-btn.right.bottom {
    right: 42px;
    bottom: 42px;
  }
}

@media screen and (min-width: 768px) {
  .btn {
    font-size: var(--fs-sl);
  }

  .btn.small {
    padding: 12px 16px;
    font-size: var(--fs-s);
  }

  .fab-btn {
    padding: 14px;
  }

  .fab-btn svg {
    width: 32px;
    height: 32px;
  }

  .fab-btn.left.bottom {
    left: 52px;
    bottom: 32px;
  }

  .fab-btn.right.bottom {
    right: 52px;
    bottom: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .btn {
    font-size: var(--fs-l);
  }

  .btn.small {
    padding: 12px 16px;
    font-size: var(--fs-l);
  }
}

@media screen and (min-width: 1600px) {
  .fab-btn {
    padding: 18px;
  }

  .fab-btn svg {
    width: 42px;
    height: 42px;
  }

  .fab-btn.left.bottom {
    left: calc(50% - 810px);
    bottom: 42px;
  }

  .fab-btn.right.bottom {
    right: calc(50% - 810px);
    bottom: 42px;
  }
}
